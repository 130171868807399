import React, { useState, useContext, FC, Key, useEffect } from "react"
import { Link } from "react-router-dom"
import { Table } from "antd"
import type { ColumnsType } from "antd/es/table"
import { UserOutlined } from "@ant-design/icons"
import type { MenuProps } from "antd"
import { Dropdown, Space } from "antd"
import { AllUserDataType } from "../types/DataType"
import UserContact from "../component/UserContact"
import user from "../../../../assets/user-img.png"
import { SidebarContext, isAuth } from "../../../auth/ProtectedRoute"
import axios from "axios"
import moment from "moment"
import SkeletonTable, { SkeletonTableColumnsType } from "../../../../components/SkeletonTable"

const CompleteRegTable = () => {
  const { userName, sidebarData } = useContext(SidebarContext)
  const [loading, setLoading] = useState<boolean>(false)
  const [userTableData, setUserTableData] = useState<AllUserDataType[]>([])

  const [showTotal, setShowTotal] = useState<number>()
  const [record, setRecord] = useState<AllUserDataType>()

  //api request for table data

  const showAllUserTable = (page: number) => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_HOST_API + `manage-user/list?type=completed_registrations`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then(res => {
        setLoading(false)
        setUserTableData(
          res.data.data.map((userData: AllUserDataType) => ({
            key: userData.project_id,
            project_id: userData.project_id,
            photo: process.env.REACT_APP_IMAGE_API_PREFIX + userData.photo,
            name: userData.name,
            email: userData.email,
            location: userData.location,
            date_registered: userData.date_registered,
            last_login_date: userData.last_login_date,
            status: "Complete Registration",
            action: "",
          }))
        )
        setShowTotal(res?.data?.data?.total)
      })
      .catch(err => {
        console.log(err)
      })
  }
  useEffect(() => {
    showAllUserTable(1)
  }, [])

  const items: MenuProps["items"] = [
    {
      label: "Edit Profile",
      key: "1",
      icon: <UserOutlined />,
    },
    {
      label: (
        <div>
          <Link to="view-profile" state={{ userName: userName, sidebar: sidebarData }}>
            View Profile
          </Link>
        </div>
      ),
      key: "2",
      icon: <UserOutlined />,
    },
    {
      label: "Delete",
      key: "3",
      icon: <UserOutlined />,
    },
  ]

  const menuProps = {
    items,
  }

  const columns: ColumnsType<AllUserDataType> = [
    {
      title: "Photo",
      dataIndex: "photo",
      render: image => (
        <div
          style={{
            width: "75px",
            height: "80px",
          }}
        >
          <img
            src={image}
            alt="logo"
            style={{ width: "100%", height: "100%", borderRadius: "50%" }}
          />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      render: name => (
        <div>
          <p className="user_name">{name}</p>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      render: text => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
    {
      title: "Location",
      dataIndex: "location",
      render: text => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
    {
      title: "Date Registered",
      dataIndex: "date_registered",
      render: date_registered => (
        <div>
          <p className="user_name">{moment(date_registered).format("DD/MM/YYYY")}</p>
        </div>
      ),
    },
    {
      title: "Last Login Date",
      dataIndex: "last_login_date",
      render: last_login_date => (
        <div>
          <p className="user_name">{moment(last_login_date).format("DD/MM/YYYY")}</p>
        </div>
      ),
    },
    // {
    //   title: "Contact User",
    //   dataIndex: "contactUser",
    //   render: () => (
    //     <div>
    //       <UserContact />
    //     </div>
    //   ),
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   render: () => (
    //     <div>
    //       <Space wrap>
    //         <Dropdown.Button
    //           menu={menuProps}
    //           placement="bottomCenter"
    //           icon={<UserOutlined />}
    //         ></Dropdown.Button>
    //       </Space>
    //     </div>
    //   ),
    // },
  ]

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: Key[], selectedRows: AllUserDataType[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows)
    },
    // getCheckboxProps: (record: AllUserDataType) => ({
    //   disabled: record.name === "Disabled User", // Column configuration not to be checked
    //   name: record.name,
    // }),
  }
  const [selectionType, setSelectionType] = useState<"checkbox" | "radio">("checkbox")

  return (
    <div>
      <SkeletonTable loading={loading} columns={columns as SkeletonTableColumnsType[]}>
        <Table
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
          columns={columns}
          dataSource={userTableData}
          pagination={{
            showSizeChanger: false,
            pageSize: 20,
            total: showTotal,
            onChange: page => {
              showAllUserTable(page)
            },
            showTotal: (showTotal, range) => `${range[0]}-${range[1]} of ${showTotal} items`,
          }}
          onRow={record => {
            return {
              onClick: () => {
                setRecord(record)
              },
            }
          }}
        />
      </SkeletonTable>
    </div>
  )
}

export default CompleteRegTable
