import React, { Fragment, FC } from "react";
import user from "../../../assets/user-img.png";
import SystemUserTable from "./tables/SystemUserTable";
import useModal from "../../../components/modal/useModal";
import AddUserModal from "./AddUserModal";

const SystemUser: FC = () => {
  const { isOpen, toggle } = useModal();
  return (
    <Fragment>
      <div className="roles">
        <div className="roles-wrapper">
          <div className="roles--heading">
            <div className="roles--heading-img">
              <img src={user} alt="profile" />
            </div>
          </div>
          <div className="roles--tables">
            <div className="roles--tables-heading">
              <div className="roles--tables-heading-title">
                <h2>System Users</h2>
              </div>
              <div className="roles--tables-heading-box">
                <div className="formy">
                  <form>
                    <div className="formy--box">
                      <input type="text" placeholder="Search.." name="search" />
                      <div className="formy--box-icon">
                        <i className="fa fa-search"></i>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="clicks">
                  <div className="clicks--right">
                    <button onClick={toggle}>
                      <span>
                        <i className="fa fa-plus-circle" aria-hidden="true"></i>
                      </span>
                      Add User
                    </button>
                    <AddUserModal
                      isOpen={isOpen}
                      toggle={toggle}
                    ></AddUserModal>
                  </div>
                  <div className="clicks--left">
                    <button>
                      <span>
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </span>
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="roles--tables--content">
              <SystemUserTable />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default SystemUser;
