import React, { Fragment, useContext, FC, useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import "../styles/sidebar.scss";
import logo from "../assets/logo.png";
import user from "../assets/user-img.png";
import axios from "axios";
import { isAuth, SidebarContext } from "../pages/auth/ProtectedRoute";

const AdminSidebar: FC = () => {
  const { userName, sidebarData } = useContext(SidebarContext);

  const [active, setActive] = useState<number | null>(null);
  const [showAdmin, setShowAdmin] = useState<boolean>(false);
  const [showPanel, setShowPanel] = useState<boolean>(false);
  const [showSetup, setShowSetup] = useState<boolean>(false);

  const navigate = useNavigate();

  const activeStyle = {
    color: "#4895ef",
    background: "#1b1b1b",
  };

  const logout = () => {
    axios({
      method: "POST",
      url: process.env.REACT_APP_HOST_API + `auth/logout`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then((res) => {
        // console.log(res.data.response);
        if (window !== undefined && res.data.response === true) {
          sessionStorage.removeItem("user");
          sessionStorage.removeItem("activityDetail");
        }
      })
      .then(() => {
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggleClass = (moduleId: number) => {
    setActive(moduleId);
  };

  return (
    <>
      <div className="sidebar">
        <div className="sidebar__logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="sidebar__userprofile">
          <Link to="">
            <div className="user">
              <div className="user__pic">
                <img src={user} alt="profile" />
              </div>
              <h1>{userName}</h1>
              <p>{sidebarData.name}</p>
            </div>
          </Link>
        </div>
        <div className="moduleContent">
          <div className="top">
            <div>
              {/* <Menu>
              <SubMenu label="Charts">
                <MenuItem> Pie charts </MenuItem>
                <MenuItem> Line charts </MenuItem>
              </SubMenu>
              <MenuItem> Documentation </MenuItem>
              <MenuItem> Calendar </MenuItem>
            </Menu> */}
            </div>
            <div className="top__nav-link">
              {sidebarData
                ? sidebarData !== null &&
                  sidebarData.modules.map((module, index) => {
                    let strClass = "";
                    if (active == index) {
                      // console.log(index);
                      strClass = "top__nav-link-li-nav--active";
                    } else {
                      strClass = "top__nav-link-li-nav";
                    }
                    return (
                      <div className="top__nav-link-li" key={index}>
                        <div
                          onClick={() => {
                            toggleClass(index);
                            setShowSetup(false);
                            setShowAdmin(false);
                            setShowPanel(false);
                          }}
                          className={strClass}
                          // className="top__nav-link-li-nav"
                        >
                          <div
                            // end
                            // to="#"
                            // className={
                            //   moduleId === module.id ? "ic" : "activeClass"
                            // }
                            className={
                              strClass === "top__nav-link-li-nav"
                                ? "ic"
                                : "activeClass"
                            }
                            // onClick={() => {}}
                          >
                            {" "}
                            {module.name}
                          </div>
                        </div>
                        {active == index ? (
                          <ul className="sub-menu">
                            {module.sub_module.map(
                              (sub: {
                                id: number;
                                name: string;
                                route: string;
                              }) => {
                                // let route = sub.route.substr(41);
                                // console.log(route);
                                return (
                                  <li className="sub-menu--links" key={sub.id}>
                                    <NavLink
                                      className="sub-menu--links_name"
                                      to={{ pathname: `/admin/${sub.route}` }}
                                      state={{
                                        userName: userName,
                                        sidebar: sidebarData,
                                      }}
                                      style={({ isActive }) =>
                                        isActive ? activeStyle : undefined
                                      }
                                    >
                                      {sub.name}
                                    </NavLink>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
          {sidebarData.id === 1 ? (
            <Fragment>
              <hr className="divider" />
              <div className="bottoms">
                <div className="bottoms--btn">
                  <ul className="bottoms--btn-wrap">
                    <li className="bottoms--btn-wrap-li">
                      <div
                        className={
                          !showAdmin
                            ? "bottoms--btn-wrap-li-nav"
                            : "bottoms--btn-wrap-li-nav--active"
                        }
                      >
                        <div
                          // to="#"
                          className={!showAdmin ? "ic" : "activeClass"}
                          onClick={() => {
                            setShowSetup(false);
                            setShowAdmin(true);
                            setShowPanel(false);
                            setActive(null);
                          }}
                        >
                          {" "}
                          System Administrator
                        </div>
                      </div>
                      {showAdmin ? (
                        <ul className="sub-menu">
                          <li className="sub-menu--links">
                            <NavLink
                              className="sub-menu--links_name"
                              to={{ pathname: "admin/system-admin/roles" }}
                              state={{
                                userName: userName,
                                sidebar: sidebarData,
                              }}
                              style={({ isActive }) =>
                                isActive ? activeStyle : undefined
                              }
                            >
                              Admin Roles
                            </NavLink>
                          </li>
                          <li className="sub-menu--links">
                            <NavLink
                              className="sub-menu--links_name"
                              to={{ pathname: "admin/system-admin/admin-user" }}
                              state={{
                                userName: userName,
                                sidebar: sidebarData,
                              }}
                              style={({ isActive }) =>
                                isActive ? activeStyle : undefined
                              }
                            >
                              Admin Users
                            </NavLink>
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}
                    </li>
                    <li className="bottoms--btn-wrap-li">
                      <div
                        className={
                          !showPanel
                            ? "bottoms--btn-wrap-li-nav"
                            : "bottoms--btn-wrap-li-nav--active"
                        }
                      >
                        <div
                          // to="#"
                          className={!showPanel ? "ic" : "activeClass"}
                          onClick={() => {
                            setShowSetup(false);
                            setShowAdmin(false);
                            setShowPanel(true);
                            setActive(null);
                          }}
                        >
                          {" "}
                          Left Panel
                        </div>
                      </div>
                      {showPanel ? (
                        <ul className="sub-menu">
                          <li className="sub-menu--links">
                            <NavLink
                              className="sub-menu--links_name"
                              to={{ pathname: "admin/system-admin/modules" }}
                              state={{
                                userName: userName,
                                sidebar: sidebarData,
                              }}
                              style={({ isActive }) =>
                                isActive ? activeStyle : undefined
                              }
                            >
                              Modules
                            </NavLink>
                          </li>
                          <li className="sub-menu--links">
                            <NavLink
                              className="sub-menu--links_name"
                              to={{ pathname: "admin/system-admin/submodules" }}
                              state={{
                                userName: userName,
                                sidebar: sidebarData,
                              }}
                              style={({ isActive }) =>
                                isActive ? activeStyle : undefined
                              }
                            >
                              Sub-modules
                            </NavLink>
                          </li>
                          <li className="sub-menu--links">
                            <NavLink
                              className="sub-menu--links_name"
                              to={{ pathname: "admin/system-admin/actions" }}
                              state={{
                                userName: userName,
                                sidebar: sidebarData,
                              }}
                              style={({ isActive }) =>
                                isActive ? activeStyle : undefined
                              }
                            >
                              Actions
                            </NavLink>
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}
                    </li>
                    <li className="bottoms--btn-wrap-li">
                      <div
                        className={
                          !showSetup
                            ? "bottoms--btn-wrap-li-nav"
                            : "bottoms--btn-wrap-li-nav--active"
                        }
                      >
                        <div
                          // to="#"
                          className={!showSetup ? "ic" : "activeClass"}
                          onClick={() => {
                            setShowSetup(true);
                            setShowAdmin(false);
                            setShowPanel(false);
                            setActive(null);
                          }}
                        >
                          {" "}
                          Setups
                        </div>
                      </div>
                      {showSetup ? (
                        <ul className="sub-menu">
                          <li className="sub-menu--links">
                            <NavLink
                              className="sub-menu--links_name"
                              to="#"
                              state={{
                                userName: userName,
                                sidebar: sidebarData,
                              }}
                              style={({ isActive }) =>
                                isActive ? activeStyle : undefined
                              }
                            >
                              Configurations
                            </NavLink>
                          </li>
                          <li className="sub-menu--links">
                            <NavLink
                              className="sub-menu--links_name"
                              to="#"
                              state={{
                                userName: userName,
                                sidebar: sidebarData,
                              }}
                              style={({ isActive }) =>
                                isActive ? activeStyle : undefined
                              }
                            >
                              Feature Activation
                            </NavLink>
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </Fragment>
          ) : null}
        </div>
        <div className="logout">
          <button onClick={logout}>
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.1667 6.66667L17.2867 8.54667L19.3933 10.6667H8.5V13.3333H19.3933L17.2867 15.44L19.1667 17.3333L24.5 12L19.1667 6.66667ZM3.16667 2.66667H12.5V0H3.16667C1.7 0 0.5 1.2 0.5 2.66667V21.3333C0.5 22.8 1.7 24 3.16667 24H12.5V21.3333H3.16667V2.66667Z"
                fill="#4895EF"
              />
            </svg>
            Logout
          </button>
        </div>
      </div>
    </>
  );
};

export default AdminSidebar;
